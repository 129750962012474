import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Map from "../components/map/map"
import IncidentsBar from "../components/incidents/incidents_bar"
import { getIncidents } from "../services/api"
import NotificationsBar from "../components/notifications/notifications_bar"
import Filters from "../components/map/filters"
import Countries from "../components/map/countries"
import useFilters from "../components/common/filters"
import HolidayBanner from "../components/map/holiday_banner/holiday_banner"
import {hasAllIncidentAccess} from "../services/auth"
import { navigate } from "gatsby"


const IncidentMap = () => {
	const [showBar, setShowBar] = React.useState(true)
	const [showNotificationsBar, setShowNotificationsBar] = React.useState(false)
  const [showNotificationSubbar, setShowNotificationSubbar] = React.useState(false)
  const [incidents, setIncidents] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const [filters, countriesSelected, filterController, countryController] = useFilters();

	let sidebar = []

  if(!hasAllIncidentAccess() && typeof window != 'undefined'){
    navigate("/404")
    return (<></>)
  }

  // load incidents, also used in callback
  const loadIncidents = (_filters) => {
    if(typeof(_filters) != 'undefined' && _filters != null) {
      filterController.setFilters(_filters)
    }
    setLoading(true)

    getIncidents(_filters).then(incidents => {
			if(incidents != null) {
				setIncidents(incidents)
			} else {
        setIncidents([])
      }
      setLoading(false)
		})
  }

	if(showBar) {
		sidebar = (<aside className="alert-sidebar col-md-4 col-sm-12 floating">
          <IncidentsBar close={true} closeCallback={(e) => {setShowBar(false)}} 
          showCountry={false} 
          incidents={incidents} 
          loading={loading}
          filterController={filterController}
          filters={filters}
          />
        </aside>)
	} else if(showNotificationsBar) {
    let subbar = (<> </>);
    if(showNotificationSubbar) {
      subbar = (
        <aside className="filter-menu alert-sidebar notifications subbar col-md-4 col-sm-12 floating">
          <Countries countryController={countryController} closeCallback={(e) =>{setShowNotificationSubbar(false)}} countries={countriesSelected} key="1"/> 
          <Filters
            setFilterCallback={ filterController.toggleFilter }
            filters={filters}
            closeCallback={ (e) => { setShowNotificationSubbar(false) } } key="2"/>
        </aside>
      )
    }
    sidebar = (
      <div>
        <NotificationsBar countries={countriesSelected} filters={filters} closeCallback={(e) => {setShowNotificationsBar(false)}} showSubBarCallback={() => { setShowNotificationSubbar(true) }}/>
        {subbar}
      </div>
    )
  }

	return (
  <Layout>
    <Seo title="PostNL Early Warning System Map" />
    <div className="the-map">
  		<HolidayBanner/>
      <Map loadIncidentsCallback={loadIncidents} 
      incidentsCallback={setShowBar} 
      notificationsCallback={setShowNotificationsBar} 
      filterController={filterController} 
      countryController={countryController} 
      incidents={incidents} 
      countries={countriesSelected} 
      filters={filters} 
      page={"incident_map"} />
    </div>
    {sidebar}
    
  </Layout>
	)
}

export default IncidentMap
